
<template>
  <section id="RoKitClubEventPhotos">
    <div class="dividerOne"></div>
    <div class="dividerTwo"></div>
    <h1>
      Event Photos
      <div class="line"></div>
    </h1>
    <EventSpacesCarousel :carouselImages="carouselImages" />
  </section>
</template>

<script>
  import EventSpacesCarousel from '@/components/global/EventSpacesCarousel'
  import { getEventPhotos } from '@/data/sectionData/eventSpaces/RoKitClub'

  export default {
    name: "RoKitClubEventPhotos",
    components: {
      EventSpacesCarousel
    },
    data() {
      return {
        carouselImages: getEventPhotos()
      }
    }
};
</script>

<style lang="scss" scoped>
  #RoKitClubEventPhotos {
    position: relative;
    h1 {
      font-family: 'ArtegraSans-Bold';
      font-size: 26px;
      color: #FFFFFF;
      letter-spacing: 1.62px;
      line-height: 25px;
      width: fit-content;
      margin-bottom: 20px;
      text-transform: uppercase;
      @media (min-width: $lg) {
        display: none;
      }
      @media (max-width: 993px) {
        margin-left: 100px;
      }
      @media (max-width: $md) {
        margin-left: 50px;
      }
      @media (max-width: $sm) {
        margin-left: 20px;
      }
      .line {
        @media (min-width: $lg) {
        display: none;
      }
        height: 2px;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0 0 9px 2px #FFFFFF;
        margin-top: 20px;
      }
    }
    .dividerOne {
      width: 100%;
      height: 2px;
      background: #1A1A1A;
      margin-top: 40px;
      @media (min-width: $lg) {
        display: none;
      }
    }
    .dividerTwo {
      width: 100%;
      height: 2px;
      background: #2C2C2C;
      margin-bottom: 40px;
      @media (min-width: $lg) {
        display: none;
      }
    }
  }
</style>
